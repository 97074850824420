import axios from 'axios';
import Globals from '../core/globals';

class AnalyticsService {
    baseUri = `${Globals.backendUri}/analytics/`;

    countCampaignClick(identifier, name) {
        return axios.post(`${this.baseUri}campaign/`, {
            name,
            identifier,
        });
    }

    countLinkClick(identifier, name) {
        return axios.post(`${this.baseUri}link/`, {
            name,
            identifier,
        });

    }

    countEventClick(identifier, name) {
        return axios.post(`${this.baseUri}event/`, {
            name,
            identifier,
        });

    }
}

export default new AnalyticsService();
